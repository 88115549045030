export const state = () => ({
    vapidKey: null,
    contentTypes: [],
    contentMaps: [],
    metaMap: [],
    registry: {},
    taxonomies: {},
    menus: [],
    userSubMenu: {},
    tasks_count: 0,
    isMenuOpen: false,
    menuItemActiveIndex: null,
    menuProgress: null,
    userInfos: {},
    checklist: {},
    nextLesson: null,
    lessonNav: null,
    allItemsLoaded: null,
    isIdcBannerOpen: true,
    isSmallDevice: false,
    breakHeader: false,
    paypal: null,
    jsons: {},
    xs: false,
})

export const mutations = {
    setVapidKey(state, data) {
        state.vapidKey = data
    },

    setContentTypes(state, data) {
        state.contentTypes = data
    },

    setMetaMap(state, data) {
        state.metaMap = data
    },

    setRegistry(state, data) {
        state.registry = data
    },

    setTaxonomies(state, data) {
        state.taxonomies = data
    },

    setContentMaps(state, data) {
        state.contentMaps = data
    },

    setMenus(state, data) {
        state.menus = data
    },

    setUserSubMenu(state, data) {
        state.userSubMenu = data
        if (data?.activeIndex) state.menuItemActiveIndex = data.activeIndex
    },

    setTasksCount(state, data) {
        state.tasks_count = data
    },

    toggleMenuOpen(state) {
        state.isMenuOpen = !state.isMenuOpen
    },

    setMenuProgress(state, data) {
        state.menuProgress = data
    },

    setUserInfos(state, data) {
        state.userInfos = data
    },

    setChecklistCount(state, data) {
        state.checklist = data
    },

    setNextLesson(state, data) {
        state.nextLesson = data
    },

    setLessonNav(state, data) {
        state.lessonNav = data
    },

    setAllItemsLoaded(state, data) {
        state.allItemsLoaded = data
    },

    setIdcBannerOpen(state, data) {
        state.isIdcBannerOpen = data
    },

    setPaypal(state, data) {
        state.paypal = data
    },

    setJsons(state, data) {
        state.jsons = data
    },
    setDeviceSize(state, isSmallDevice) {
        state.isSmallDevice = isSmallDevice
    },
    setBreakHeader(state, breakHeader) {
        state.breakHeader = breakHeader
    },
    setResolution(state, [size, matches]) {
        ;['xs'].forEach((resolution) => {
            if (resolution === size) state[`${size}`] = matches
            else state[`${size}`] = false
        })
    },
}

export const actions = {
    async fetch({ commit }) {
        const data = await this.$axios.$get('/api/v1/data')
        commit('setTasksCount', data.tasks_count)
        commit('setVapidKey', data.vapid_key)
        commit('setMenus', data.menus.menus)
        commit('setContentTypes', data.content_types || [])
        commit('setMetaMap', data.meta_map)
        commit('setRegistry', data.registry)
        commit('setTaxonomies', data.taxonomies || {})
        commit('setContentMaps', data.content_maps || [])
        commit('setPaypal', data.paypal || null)
        commit('setJsons', data.jsons || {})
    },

    async flushCache({ dispatch }) {
        await dispatch('fetch')
    },

    setUserSubMenu({ commit }, data) {
        commit('setUserSubMenu', data)
    },

    setMenuProgress({ commit }, data) {
        commit('setMenuProgress', data)
    },

    setUserInfos({ commit }) {
        this.$axios.$get('api/v1/users/me/dashboard').then((res) => {
            commit('setUserInfos', res.data)
        })
    },

    setNextLesson({ commit }, data) {
        commit('setNextLesson', data)
    },

    setLessonNav({ commit }, data) {
        commit('setLessonNav', data)
    },

    setChecklistCount({ commit }, data) {
        commit('setChecklistCount', data)
    },

    setAllItemsLoaded({ commit }, data) {
        commit('setAllItemsLoaded', data)
    },
    setDeviceSize({ commit }, isSmallDevice) {
        commit('setDeviceSize', isSmallDevice)
    },
    setBreakHeader({ commit }, breakHeader) {
        commit('setBreakHeader', breakHeader)
    },
    setResolution({ commit }, payload) {
        commit('setResolution', payload)
    },
}

export const getters = {
    isXs: (state) => state.xs,
    smDevice: (state) => state.isSmallDevice,
    lgDevice: (state) => !state.isSmallDevice,
    breakHeader: (state) => state.breakHeader,
}
